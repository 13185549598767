import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Top from '../components/top'
import Footer from '../components/footer'
import Header from '../components/header'
import Contact from '../components/contact'
import Text from '../components/text'
import TextImage from '../components/text_image'
import Features from '../components/features'
import Entries from '../components/entries'
import Faq from '../components/faq'
import { GatsbyImage } from 'gatsby-plugin-image'
import Flickity from 'react-flickity-component'
import { PrismicRichText } from '@prismicio/react'

const flickityOptions = {
  prevNextButtons: true,
  pageDots: false,
  wrapAround: true,
  setGallerySize: false,
  cellAlign: 'center',
  autoPlay: 8000,
}

const HomePage = ({data}) => {
  const entry = data.prismicHomePage

  /*
  const [isLoaded, setIsLoaded] = React.useState('slider-hide');
  React.useEffect(() => {
    setIsLoaded("slider-loaded flickity-enabled is-draggable");
  }, [isLoaded]);
  */

  if (!entry) return null
  return (
    <> 
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Top />
      <Header />

      <div className="w-full relative">
        <Flickity className={'slider'} elementType={'div'} options={flickityOptions} disableImagesLoaded={false} reloadOnUpdate={false} static={true}>
        {entry.data.slides.map((slide,i) => {
          return (
            <div key={`${i}`} className="w-full h-full flex items-center">
              <div className="w-full mx-auto">
                <div className="absolute -inset-0">
                  <GatsbyImage className="w-full h-full object-cover block" image={slide.image.gatsbyImageData} alt={slide.image.alt ?? ''} />
                </div>
                <div className="absolute -inset-0">
                  <div className="w-full h-full bg-black opacity-40"></div>
                </div>
                <div className="relative">
                  <div className="w-10/12 mx-auto max-w-screen-xl">
                    <div className="flex-none md:flex justify-between gap-16">
                      <div className="w-full md:w-10/12 lg:w-8/12">
                        <div className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-sans text-white">
                          <h2>{slide.heading}</h2>
                        </div>
                        <div className="mt-4 text-base md:text-lg text-grey-c">
                          <PrismicRichText field={slide.text.richText} /> 
                        </div>
                        <div className="mt-12">
                          <Link to={linkResolver(slide.page_link)} className="rounded-md text-lg border-2 border-white text-white px-4 py-2">{slide.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                        </div>
                      </div>
                      <div className="w-full md:w-2/12 lg:w-4/12">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        </Flickity>
      </div>

      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text_image') {
          return (
            <div key={slice.id}>
              <TextImage primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'features') {
          return (
            <div key={slice.id}>
              <Features primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'entries') {
          return (
            <div key={slice.id}>
              <Entries primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'faq') {
          return (
            <div key={slice.id}>
              <Faq primary={slice.primary} items={slice.items} />
            </div>
          )
        }
      })}
      
      <Contact />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicHomePage {
    data {
      meta_description
      page_title
      slides {
        heading
        text {
          richText
        }
        image {
          alt
          gatsbyImageData
        }
        button
        page_link {
          url
          uid
          type
        }
      }
      body {
        ... on PrismicHomePageDataBodyText {
          id
          primary {
            text {
              richText
            }
          }
          slice_type
        }
        ... on PrismicHomePageDataBodyTextImage {
          id
          slice_type
          primary {
            layout
            text {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
            button
            page_link {
              url
              uid
              type
              link_type
            }
          }
        }
        ... on PrismicHomePageDataBodyFeatures {
          id
          slice_type
          primary {
            heading1
            text {
              richText
            }
          }
          items {
            text {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicHomePageDataBodyEntries {
          id
          slice_type
          primary {
            heading1
            text {
              richText
            }
          }
          items {
            heading1
            text {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
            page_link {
              url
              uid
              type
            }
          }
        }
        ... on PrismicHomePageDataBodyFaq {
          id
          slice_type
          primary {
            heading1
            text {
              richText
            }
          }
          items {
            heading1
            text {
              richText
            }
          }
        }
      }
    }
  }
}
`

export default HomePage
